<template>
  <div class="appointmentList-view">
    <div class="d-flex">
      <div  style="margin-bottom:20px">
        <el-input
            clearable
            v-model="membersName"
            style="width: 200px;margin-left: 8px"
            placeholder="请输入会员名称"
            maxlength="30"
        ></el-input>
        <el-button
            class="v-primary ml10"
            type="primary"
            @click="queryAppointmentPage"
        >查询</el-button
        >
      </div>
      <div style="margin-left: auto">
        <el-button class="ml10" type="primary" @click="addHY"
        >添加会员
        </el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :loading="loading"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="index" align="center" label="编号"/>
        <el-table-column prop="userName" align="center" label="会员名称" show-overflow-tooltip/>
        <el-table-column prop="shareNum" align="center" label="会员照片">
          <template slot-scope="scope">
            <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.img"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="gender" align="center" label="性别"/>
        <el-table-column prop="tel" align="center" label="联系电话" width="130px"/>
        <el-table-column prop="level" align="center" label="入会级别" width="100px"/>
        <el-table-column prop="nickname" align="center" label="微信昵称" width="200px"/>
        <el-table-column align="center" label="微信头像">
          <template slot-scope="scope">
            <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.wxImg"
                :preview-src-list="srcList1">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" align="center" label="企业名称" width="200px"/>
        <el-table-column prop="state" align="center" label="操作">
          <template slot-scope="scope">
            <customPopconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm='del(scope.row)'
                icon="el-icon-info"
                icon-color="red"
                title="确定要移除吗？">
              <el-button slot="reference" style="color: #fd634e" type="text">移除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="会员列表"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <commonTable
          :tableData="memberList"
          @handleSizeChange="handleSizeChange1"
          @handleCurrentChange="handleCurrentChange1"
          @handleSelectionChange="handleSelectionChange"
          :currentPage="currentPage1"
          :loading="loading"
          :total="total1"
      >
        <template v-slot:table>
          <el-table-column type="selection"/>
          <el-table-column prop="userName" align="center" label="会员名称" show-overflow-tooltip/>
          <el-table-column prop="gender" align="center" label="性别"/>
          <el-table-column prop="tel" align="center" label="联系电话" show-overflow-tooltip/>
        </template>
      </commonTable>

      <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="toSub">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import customPopconfirm from "@/components/common/customPopconfirm";
import commonTable from "@/components/common/commonTable";
import {selectMemberPageList,addMemberGroupReation,queryGroupMemberInfo,removeMemberGroupReation} from "@/api/members";
import {selectMemberFeeList} from '@/api/organization.js'
import {Base64 as base64} from "js-base64";

export default {
  name: "toMemberGroupList",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      srcList: [],
      inputMaxL:10,
      centerDialogVisible:false,
      membersName: "",
      userInfo: this.$store.state.loginRoot.userInfo,
      tableData: [],
      total: 0, //总条数
      total1: 0, //总条数
      currentPage: 1, //当前页数
      currentPage1: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      pageNum1: 1,
      pageSize1: 10,
      loading: false, //加载中
      userId:'',
      memberId:'',
      memberList:[],
      ossUrl: this.$store.state.ossUrl,
      multipleSelection:[]
    };
  },
  mounted() {
    this.queryPageMoney()
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async queryPageMoney() {
      let data = {
        associationId: localStorage.getItem("associationId"),
      };
      try {
        const result = await selectMemberFeeList(data);
        this.industryList = result.data;
        this.queryPage();
      } catch (error) {

      }
    },
    /**@method 获取*/
    async queryPage(payload) {
      let data = {
        groupId:this.$route.query.groupId,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await queryGroupMemberInfo(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        let a = []
        let b = []
        for (let i in list) {
          if (list[i].img) {
            a.push(list[i].img)
          }
          if (list[i].wxImg) {
            b.push(list[i].wxImg)
          }
          if (list[i].nickname) {
            list[i].nickname = base64.decode(list[i].nickname);
          }
          list[i].version = list[i].version == 1 ? '高级' : '普通'
          list[i].gender = list[i].gender == 1 ? '男' : '女'
          if (list[i].cardSwitch == 0) {
            list[i].cardSwitch = true
          } else {
            list[i].cardSwitch = false
          }
          for (let j in this.industryList) {
            if (list[i].level == this.industryList[j].memberLevelId) {
              list[i].level = this.industryList[j].levelName
            }
          }
        }
        this.srcList = a
        this.srcList1 = b
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {
      this.currentPage = 1;
      this.pageNum = 1;
      let data = {
        userName:this.membersName,
      }
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        userName:this.membersName,
      }
      this.queryPage(data);
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        userName:this.membersName,
      }
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.toMemberList();
    },
    //当前页查询数据条数变更
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.toMemberList();
    },
    addHY(){
      this.centerDialogVisible = true
      this.toMemberList();
    },
    toMemberList(){
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        pageSize:this.pageSize1,
        pageNum: this.currentPage1,
        examineStatusList: '0,3',
        userStatus: 1,
      }
      selectMemberPageList(ajax)
          .then((res) => {
            this.centerDialogVisible = true
            if (res.data) {
              const { total, list } = res.data.pageInfo;
              for (let i in list) {
                list[i].gender = list[i].gender == 1 ? '男' : '女'
              }
              this.memberList = list;
              this.total1 = total;
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    async toSub(){
      let list = this.multipleSelection.map((item)=>{
        return item.memberId
      })
      let data = {
        groupId:this.$route.query.groupId,
        memberIds:list.join(',')
      };
      try {
        await addMemberGroupReation(data);
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.centerDialogVisible = false
        this.queryPage()
      } catch (error) {

      }
    },
    async del(val) {
      try {
        await removeMemberGroupReation({reationId: val.reationId});
        await this.queryPage();
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>




